<template>
  <div class="member-shop-order-table">
    <BaseTable :data="displayData">
      <BaseElTableColumn label="訂單編號" prop="code" align="center" />
      <BaseElTableColumn label="訂單日期" prop="createdAt" align="center" />
      <BaseElTableColumn label="姓名" prop="name" align="center" />
      <BaseElTableColumn label="商品名稱" prop="productName" align="center" />
      <BaseElTableColumn label="商品數量" prop="productQuantity" align="center" />
      <BaseElTableColumn label="訂單狀態" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.status.tagType">{{ scope.row.status.label }}</Tag>
        </template>
      </BaseElTableColumn>
      <CustomFlowTableColumns
        :customFlowConfig="customFlowConfig"
        scope="memberStore"
        noFilter
      />
      <BaseElTableColumn label="訂單總額" prop="orderPrice" align="center" />
      <BaseElTableColumn label="付款方式" prop="paymentType" align="center" />
      <BaseElTableColumn label="付款狀態" prop="paymentStatus" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.paymentStatus.tagType">{{ scope.row.paymentStatus.label }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <BaseElButton class="text-primary-100 underline" type="text" @click="onRowView(scope.row)">檢視</BaseElButton>
        </template>
      </BaseElTableColumn>
    </BaseTable>
  </div>
</template>

<script>
import Tag from '@/components/Tag/Tag.vue'
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router/composables'
import { formatDate } from '@/utils/date'
import { get, find } from 'lodash'
import { orderStatusConfig, orderPaymentStatusConfig, orderPaymentTypeConfig } from '@/config/memberShop'
import CustomFlowTableColumns from '@/components/CustomFlowTableColumns.vue'

export default defineComponent({
  name: 'MemberShopOrderTable',
  components: { Tag, CustomFlowTableColumns },
  props: {
    tableData: { type: Array, default: () => [] },
    customFlowConfig: { type: Array, default: () => [] },
  },
  setup (props) {
    const router = useRouter()
    const displayData = computed(() => {
      return props.tableData.map(i => {
        const orderStatus = get(orderStatusConfig, i.status)
        const paymentStatus = get(orderPaymentStatusConfig, get(i, 'MemberStoreOrderPayment.status'))
        const paymentType = get(orderPaymentTypeConfig, get(i, 'MemberStoreOrderPayment.paymentType'))
        const product = find(get(i, 'MemberStoreOrderProducts'), item => !item.isGift)

        return {
          code: i.code,
          id: i.id,
          createdAt: formatDate(i.createdAt),
          CustomFlowRecords: i.CustomFlowRecords,
          name: get(i, 'Member.UserInfo.name'),
          productName: get(product, 'name') || '-',
          productQuantity: get(product, 'quantity') || '-',
          status: orderStatus,
          orderPrice: i.totalOriginalProductPrice,
          paymentType: paymentType.label,
          paymentStatus,
        }
      })
    })

    const onRowView = (row) => {
      router.push({ name: 'MemberShopOrderDetail', params: { id: row.id } })
    }

    return {
      displayData,
      onRowView,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
